<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <div class="d-flex justify-content-between mb-3 align-items-center">
          <h3 class="fs-6 border-start border-primary border-5 ps-3 py-1">廠商資訊</h3>
          <div>
            <button
              class="btn btn-primary btn-sm me-2"
              type="button"
              @click="openModal(brandInfo, 'showBrandModal')"
            >
              <i class="bi bi-plus-square-fill" style="top: -3px"></i>
              編輯
            </button>
            <button
              v-if="!brandInfo.isAgent && !brandInfo.isPublished && !brandInfo.isPartner"
              class="btn btn-outline-danger btn-sm me-2"
              type="button"
              @click="openModal(brandInfo, 'showDelBrandModal')"
            >
              <i class="bi bi-trash" style="top: -3px"></i>
              刪除
            </button>
          </div>
        </div>

        <div class="bg-white p-3">
          <div class="w-75 m-auto">
            <img
              :src="$imageFilters(brandInfo.imagePath)"
              :alt="brandInfo.nameCh"
              class="obj-fit-contain"
            />
          </div>

          <ul class="p-3">
            <li class="mb-4">
              <h5 class="fw-light fs-6 mb-2">公司名稱</h5>
              <h4 class="mb-1 fs-5">{{ brandInfo.nameCh }}</h4>
              <h4 class="mb-3 fs-5">{{ brandInfo.nameEn }}</h4>
            </li>

            <li class="mb-4">
              <h5 class="fw-light fs-6 mb-2">公司簡介</h5>
              <p class="fs-8">{{ brandInfo.description }}</p>
            </li>

            <li class="mb-3">
              <h5 class="fw-light fs-6">公司網址</h5>
              <a :href="brandInfo.url" target="_blank" style="word-break: break-all">{{
                brandInfo.url
              }}</a>
            </li>
            <li class="mb-3">
              <h5 class="fw-light fs-6">合作夥伴</h5>
              <p v-if="brandInfo.isPartner">
                <i class="bi bi-check-circle-fill fs-5 text-success"></i>
              </p>
              <p v-else><i class="bi bi-x-circle-fill fs-5 text-danger"></i></p>
            </li>
            <li class="mb-3">
              <h5 class="fw-light fs-6">經銷商</h5>
              <p v-if="brandInfo.isAgent">
                <i class="bi bi-check-circle-fill fs-5 text-success"></i>
              </p>
              <p v-else><i class="bi bi-x-circle-fill fs-5 text-danger"></i></p>
            </li>
            <li>
              <h5 class="fw-light fs-6">上下架</h5>
              <p v-if="brandInfo.isPublished">
                <i class="bi bi-check-circle-fill fs-5 text-success"></i>
              </p>
              <p v-else><i class="bi bi-x-circle-fill fs-5 text-danger"></i></p>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-8">
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="fs-6 border-start border-primary border-5 ps-3 py-1 mb-3">產品</h3>
            <button
              class="btn btn-outline-primary btn-sm"
              type="button"
              @click="openModal({}, 'showProductModal')"
              v-if="brandInfo.isPublished"
            >
              新增經銷產品
            </button>
          </div>
          <ul v-if="products.length">
            <li v-for="product in products" :key="product">
              <ProductItemCard
                :product-item="product"
                @edit="
                  (item) => {
                    openModal({ product, ...item }, 'showProductModal');
                  }
                "
                @del="(item) => openModal(item, 'showDelItemModal')"
                @switchPublished="toggledProductItemPublish"
              />
            </li>
          </ul>
          <p v-else class="text-center">無產品 (廠商需上架狀態才能新增)</p>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <AppModal v-model="showBrandModal" refName="brandModal" title="編輯廠商">
      <BrandCreate
        :prop-items="modalData"
        @finishContent="finishContent('showBrandModal', 'getBrandInfo')"
      />
    </AppModal>

    <AppModal v-model="showProductModal" refName="productAgentModal" title="新增/編輯產品">
      <ProductAgentCreate
        :brand-en="brandEn"
        :prop-items="modalData"
        @finishContent="finishBrandProduct"
        @cancel="
          showProductModal = false;
          modalData = {};
        "
      />
    </AppModal>

    <AppModal v-model="showDelBrandModal" refName="deleteModal" title="刪除廠商" size="md">
      <div class="px-5 py-5">是否刪除廠商 - {{ modalData.nameCh }} ?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-white px-4" @click="delBrand">
          確認
        </button>
      </div>
    </AppModal>
    <AppModal v-model="showDelItemModal" refName="deleteModal" title="刪除產品" size="md">
      <div class="px-5 py-5">是否刪除產品 - {{ modalData.name }} ?</div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-sm text-white px-4"
          @click="delProductItem({ uuid: modalData.uuid })"
        >
          確認
        </button>
      </div>
    </AppModal>
  </div>
</template>

<script>
import BrandCreate from "@/components/BrandCreate.vue";
import ProductAgentCreate from "@/components/ProductAgentCreate.vue";
import ProductItemCard from "@/components/ProductItemCard.vue";
import { apiGetAdminBrand, apiGetAdminBrandBoard, apiDeleteBrand } from "@/api/brand";
import { apiUpdateProductItem, apiDeleteProductItem } from "@/api/product";

export default {
  name: "BrandList",
  components: {
    BrandCreate,
    ProductAgentCreate,
    ProductItemCard,
  },
  data() {
    return {
      brandEn: "",
      showBrandModal: false,
      showProductModal: false,
      showDelBrandModal: false,
      showDelItemModal: false,
      modalData: {},
      brandInfo: {},
      products: [],
    };
  },
  methods: {
    async getBrandInfo() {
      try {
        const res = await apiGetAdminBrand(this.brandEn);
        const { data } = res;
        this.brandInfo = data.data;
      } catch (e) {
        // regardless
      }
    },
    async queryBrandBoard() {
      try {
        const res = await apiGetAdminBrandBoard(this.brandEn);
        const { data } = res;
        this.products = data.data;
      } catch (e) {
        // regardless
      }
    },
    async toggledProductItemPublish({ uuid, isPublished }) {
      try {
        const res = await apiUpdateProductItem(uuid, { isPublished: !isPublished });
        const { code } = res.data;
        if (code === 200) {
          this.$vHttpsNotice({ statusCode: res.status, title: "修改狀態" });
          this.queryBrandBoard();
        } else {
          this.$vErrorNotice();
        }
      } catch (e) {
        // regardless
      }
    },
    async delBrand() {
      try {
        const res = await apiDeleteBrand(this.brandEn);
        const { code, message } = res.data;
        if (code === 200) {
          this.finishContent("showDelBrandModal", "getBrandInfo");
          this.$router.push({ name: "brandList" });
        }
        this.$vHttpsNotice({ statusCode: code, title: message });
      } catch (error) {
        // regardless
      }
    },
    async delProductItem({ uuid }) {
      this.$vLoading(true);
      try {
        const res = await apiDeleteProductItem(uuid);
        const { code, message } = res.data;
        if (code === 200) {
          this.finishContent("showDelItemModal", "queryBrandBoard");
        }
        this.$vHttpsNotice({ statusCode: code, title: message });
      } catch (error) {
        // regardless
      } finally {
        this.$vLoading(false);
      }
    },
    finishBrandProduct() {
      if (!this.brandInfo.isAgent) {
        this.getBrandInfo();
      }
      this.finishContent("showProductModal", "queryBrandBoard");
    },
    openModal(item, modal) {
      this.modalData = item || {};
      this[modal] = true;
    },
    finishContent(modal, fn) {
      this.modalData = {};
      this[modal] = false;
      try {
        this[fn]();
      } catch (error) {
        // regardless
      }
    },
  },
  created() {
    this.brandEn = this.$route.params.id;
    this.getBrandInfo();
    this.queryBrandBoard();
  },
};
</script>
