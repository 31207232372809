<template>
  <div class="bg-white p-3">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <h6 class="border-bottom border-gray-400 ps-1 pe-4 pb-2 text-primary">
        {{ tempProductItem.productNameCh }}
      </h6>
    </div>
    <div
      class="bg-gray-100 pt-4 pb-3 mb-3 container"
      v-for="item in tempProductItem.items"
      :key="item.uuid"
    >
      <div class="row justify-content-between mb-3">
        <div class="col-10">
          <div class="d-flex align-items-start">
            <h5 class="me-2">{{ item.name }}</h5>
            <span
              class="badge border border-success text-success rounded-pill"
              v-if="item.isPublished"
              >經銷</span
            >
          </div>
        </div>
        <div class="col-2">
          <div class="form-check form-switch">
            <label class="form-check-label" for="flexSwitchCheckDefault">發布</label>
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              :checked="item.isPublished"
              @change="$emit('switchPublished', item)"
            />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          <div
            class="w-100 bg-gray-200 d-flex justify-content-center align-items-center"
            style="height: 120px"
          >
            <img
              :src="$imageFilters(item.imagePath)"
              alt="img"
              class="obj-fit-contain"
              v-if="item.imagePath"
            />
            <p v-else class="text-center py-4">無圖片</p>
          </div>
        </div>
        <div class="col-9">
          <p class="fs-7">
            {{ item.content || "-" }}
          </p>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-end">
        <a class="text-info fs-8 w-75 ps-1" :href="item.url" target="_blank">{{
          item.url || "無產品網址"
        }}</a>
        <div>
          <button
            class="btn btn-outline-primary btn-sm border-0"
            type="button"
            @click="$emit('edit', item)"
          >
            編輯
          </button>
          <button
            class="btn btn-outline-danger btn-sm border-0"
            type="button"
            v-if="!item.isPublished"
            @click="$emit('del', item)"
          >
            刪除
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    productItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      item: {},
    };
  },
  created() {
    this.tempProductItem = { ...this.productItem };
  },
};
</script>
